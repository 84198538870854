import 'jquery-ujs'
import './remotipart';
import 'jquery-datetimepicker';
import 'jquery-datetimepicker/jquery.datetimepicker.css';

export default class Formbuilder {
  constructor() {
    $('.new_form_response').on('ajax:success', function(event, data) {
      const $container = $(event.currentTarget).closest('.contact-form')

      if (!data.form) {
        $(event.currentTarget).replaceWith(data.payload);
      } else {
        const form = data.form.form;
        
        if (form.page_redirect) {
          window.location.href = form.redirect_path;
        } else {
          $(event.currentTarget).replaceWith(data.payload);
        }
      }

      const successEl = $container.find('.success:first')[0]

      if (successEl) {
        const rect = successEl.getBoundingClientRect()
        console.log(successEl, rect)
        window.scrollTo({
          top:
            successEl.offsetTop -
            window.innerHeight / 2 +
            successEl.clientHeight / 2,
          behavior: 'smooth',
        })
      }
    })

    $('.new_form_response').on('ajax:error', function() {
      alert('something went wrong. Please try again or contact support team');
    });

    this.initForms();
  }

  initForms() {
    $('.date-input').datetimepicker({
      timepicker: false,
      scrollMonth: false,
      scrollInput: false,
      format: 'F j, Y',
      formatTime: 'g:i a'
    });

    $('.time-input').datetimepicker({
      datepicker: false,
      scrollMonth: false,
      scrollInput: false,
      format: 'g:i a',
      formatTime: 'g:i a',
      step: 15
    });
  }
}
