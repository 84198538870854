import Siema from 'siema'

export const initGalleryHandlers = () => {
  const galleryMediaEls = [...document.querySelectorAll('.media--gallery')]

  // Bail early if no media elements.
  if (galleryMediaEls.length < 1) return

  galleryMediaEls.forEach(el => {
    const controller = new Siema({
      selector: '.media--gallery__slides',
      loop: true,
    })

    const prevButtonEl = el.querySelector('.media--gallery__navigation--prev')
    if (prevButtonEl)
      prevButtonEl.addEventListener('click', () => controller.prev())

    const nextButtonEl = el.querySelector('.media--gallery__navigation--next')
    if (nextButtonEl)
      nextButtonEl.addEventListener('click', () => controller.next())
  })
}