import Tabulator from "tabulator-tables";

export default class DataTables {
  constructor() {
    window.loadTable = this.loadTable;
  }

  loadTable(id, data) {
    const dataMutator = function(value) {
      return value ? value : "N/A";
    };

    const getHyperLink = function(cellComp) {
      var cellData = cellComp.getData();
      var data_sheet_link = cellData.data_sheet_link;

      if (!data_sheet_link) {
        return {
          url: "#"
        };
      }

      return {
        url: data_sheet_link,
        target: "_blank"
      };
    };

    const table = new Tabulator(id, {
      data,
      initialSort: [{ column: "data_sheet", dir: "asc" }],
      columns: [
        {
          title: "Data Sheet",
          field: "data_sheet",
          formatter: "link",
          formatterParams: getHyperLink
        },
        { title: "Description", field: "description", mutator: dataMutator },
        { title: "Melt", field: "melt", mutator: dataMutator },
        { title: "Izod", field: "izod", mutator: dataMutator },
        { title: "Density", field: "density", mutator: dataMutator }
      ]
    });

    const links = document.querySelectorAll(".tabulator-cell a");

    for (var i = 0; i < links.length; i++) {
      if (links[i].getAttribute("href") === "#") {
        links[i].removeAttribute("href");
      }
    }
  }
}
