import 'core-js/stable';
import 'regenerator-runtime/runtime';
import objectFitImages from 'object-fit-images';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

objectFitImages();

import DataTables from './data-tables';
import Documentation from './documentation';
import Formbuilder from './formbuilder';
import Events from './events';
import Video from './video';

new DataTables();
new Documentation();
new Formbuilder();
new Events();
new Video();

import { initGalleryHandlers } from './gallery'
import { initMenuHandlers } from './menu'

initGalleryHandlers()
initMenuHandlers()

$(document).ready(function () {
  $("body").children().each(function() {
      document.body.innerHTML = document.body.innerHTML.replace(/\u2028/g, ' ');
  });
});